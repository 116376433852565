import React, { useRef, useState } from "react";
import "./chatbotbody.scss";
import Entermessagebox from "../entermessagebox";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../input";
import Button from "../../button";
import { initializeChat } from "../../../store/slices/projectSlice";
export default function Chatbotbody({ userChatById,isKnowledgeChat,tempUserData, setTempUserData,chatWithBot, setChatWithBot }) {
  const { selectedProject } = useSelector((state) => state.project);
  const [loading, setLoading] = useState(false);

  const [userInfo, setUserInfo] = useState({});

  const [errors, setErrors] = useState({});
  const { pathname } = useLocation();
  const { route } = useParams();
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    // messagesEndRef?.current?.scrollIntoView({  scrollToBottom: -100 });
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({ ...userInfo, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const validForm = () => {
    let Fromvlid = true;
    let errors = {};
    if (!userInfo.first_name?.trim()) {
      Fromvlid = false;
      errors["first_name"] = "Please enter First Name";
    }
    if (!userInfo.last_name?.trim()) {
      Fromvlid = false;
      errors["last_name"] = "Please enter Last Name";
    }
    if (!userInfo.email?.trim()) {
      Fromvlid = false;
      errors["email"] = "Please enter Email";
    } else {
      let lastAtPos = userInfo.email.lastIndexOf("@");
      let lastDotPos = userInfo.email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          userInfo.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          userInfo.email.length - lastDotPos > 2
        )
      ) {
        Fromvlid = false;
        errors["email"] = "Please enter valid Email ID";
      }
    }
    setErrors(errors);
    return Fromvlid;
  };
  const handleOnSubmit = () => {
    if (!validForm()) return;
    let body = {
      ...userInfo,
    };
    dispatch(initializeChat(body))
      .unwrap()
      .then((res) => {
        if (!res?.data?.success) return;
        setTempUserData(res?.data?.data);
      });
  };
  return (
    <div>
      <div
        className={`chat-bot-body-alignment`}
        style={{
          background:
            (pathname.includes("generalchat")|| pathname.includes("/chat/"))&& tempUserData === ""
              ? "#0003"
              : "",
        }}
      >
        {(pathname.includes("generalchat")|| pathname.includes("/chat/")) && tempUserData === "" ? (
          <div className="input-all-message-full-screen-contnet-alignment">
            <div className="dhitbot-background-style-design">
              <h5>Welcome to Dhibot</h5>
              <p>Chat with test and get answer instantly with hight quality</p>
              <div className="name-grid">
                <Input
                  placeholder="First name"
                  name={"first_name"}
                  value={userInfo?.first_name}
                  label="First Name"
                  onChange={handleOnChange}
                  error={errors?.first_name}
                />
                <Input
                  placeholder="Last name"
                  name={"last_name"}
                  value={userInfo?.last_name}
                  label="Last Name"
                  onChange={handleOnChange}
                  error={errors?.last_name}
                />
              </div>
              <Input
                placeholder="Email"
                label="Email"
                name={"email"}
                value={userInfo?.email}
                onChange={handleOnChange}
                error={errors?.email}
              />
              <div className="submit-button-alignment">
                <Button text="Submit" onClick={handleOnSubmit} loader={loading}/>
              </div>
            </div>
          </div>
        ) : (
          <>
          <div
            className={`all-message-alignment ${
              (pathname.includes("generalchat")|| pathname.includes("/chat/"))
                ? "chat-bot-body-alignment-full-height"
                : ""
            }`}
          >
            <div className="message">
              <span>{selectedProject?.welcome_title}?</span>
            </div>
            {route == "chat-history"
              ? userChatById?.map((item, index) => {
                  return (
                    <>
                      <div className="message-right">
                        <div className="message ">
                          <span
                            style={{
                              color: selectedProject?.text_color,
                            }}
                          >
                            {item?.question}
                          </span>
                        </div>
                      </div>

                      <div className="message">
                        <span>{item?.answer}</span>
                      </div>
                    </>
                  );
                })
              : chatWithBot?.map((item, index) => {
                  return (
                    <>
                      {index % 2 === 0 ? (
                        <div className="message-right">
                          <div className="message ">
                            <span
                              style={{
                                color:
                                  index % 2 === 0 &&
                                  selectedProject?.text_color,
                              }}
                            >
                              {item}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="message">
                          <span>{item}</span>
                        </div>
                      )}
                    </>
                  );
                })}
           {loading&& <div className="message">
              <span>Typing...</span>
            </div>}
           
          <div  ref={messagesEndRef}></div>
          </div>
</>
        )}
      </div>
      {(pathname.includes("generalchat")|| pathname.includes("/chat/")) ? (
        tempUserData !== "" && (
          <Entermessagebox
          isKnowledgeChat={isKnowledgeChat}
            setChatWithBot={setChatWithBot}
            chatWithBot={chatWithBot}
            tempUserData={tempUserData}
            loading={loading}
            setLoading={setLoading}
            scrollToBottom={scrollToBottom}
          />
        )
      ) : (
        <Entermessagebox
        isKnowledgeChat={isKnowledgeChat}
          setChatWithBot={setChatWithBot}
          chatWithBot={chatWithBot}
          tempUserData={tempUserData}
          loading={loading}
          setLoading={setLoading}
          scrollToBottom={scrollToBottom}
        />
      )}
    </div>
  );
}
