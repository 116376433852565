import React, { useState } from "react";
import "./chatbotsettings.scss";
import Chatbot from "../../../components/chatbot";
import Chatbotdetails from "./chatbotdetails";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
export default function Chatbotsettings() {
const  { embeddingFile } = useSelector((state) => state.embedding);
  const [isKnowledgeChat,setIsKnowledgChat]=useState(false)


  return (
    <div>
      <div className="chatbot-setting-all-content-alignment">
        <div className="flex-box">
          <div className="flex-box-items">
            <Chatbotdetails isKnowledgeChat={isKnowledgeChat}/>
          </div>
          <div className="flex-box-items">
            <div className="tab-design-new">
              <button className={!isKnowledgeChat&&"active"} onClick={()=>setIsKnowledgChat(false)}>General Chat</button>
              <button className={isKnowledgeChat&&"active"}  onClick={()=>{
                if(embeddingFile?.length<=0){
                  toast.error("No Knowledge Source available")
                  return;
                }
                setIsKnowledgChat(true)}}>Knowledge Chat</button>
            </div>
            <Chatbot isKnowledgeChat={isKnowledgeChat}/>
          </div>
        </div>
      </div>
    </div>
  );
}
