import React from 'react'
import './button.scss';
export default function Button({text, loader , onClick,className,disabled}) {
  return (
    <div className={'button'} onClick={()=>{!disabled&&onClick&&onClick()}}>
      <button className={className?className:""}  disabled={disabled}>{text}
      {
        loader && (
          <div class="loader"></div>
        )
      }
      </button>
    </div>
  )
}
