import React, { useEffect } from "react";
import "./login.scss";
import Authheader from "../../components/authheader";
import AuthVector from "../../assets/icons/auth-vector.svg";
import EyeIcon from "../../assets/icons/eye.svg";
import HideEyeIcon from "../../assets/icons/hideeye.svg";
import Input from "../../components/input";
import Button from "../../components/button";
import Loginwithgoogle from "../../components/loginwithgoogle";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { signIn } from "../../store/slices/authSlice";
import { checkPasswordValidation } from "../../common/validation";
export default function Login() {
  const [signInData, setSignInData] = useState({});
  const [showEyeIcon, setShowEyeIcon] = useState(false);
  const [error, setErrors] = useState({});
  const [loading,setLoading]=useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    // Check if 'rememberMe' is set in localStorage
    const rememberMe = localStorage.getItem("rememberMe") ==="true";
    if (rememberMe) {
        const savedUseremail = localStorage.getItem("email");
        const savedPassword = localStorage.getItem("password");

        if (savedUseremail && savedPassword) {
    
            setSignInData({...signInData,password:savedPassword,email:savedUseremail,rememberMe:true})
          
        }
    }
}, []);
  const handleOnChange = (e) => {
    const { name, value , checked} = e.target;
    if(name==="rememberMe"){
      setSignInData({ ...signInData, [name]: checked });
      return;
    }
    setSignInData({ ...signInData, [name]: value });
    setErrors({ ...error, [name]: "" });
  };
  const validForm = () => {
    let Fromvlid = true;
    let errors = {};

    if (!signInData.email?.trim()) {
      Fromvlid = false;
      errors["email"] = "Email Id is required*";
    } else {
      let lastAtPos = signInData.email.lastIndexOf("@");
      let lastDotPos = signInData.email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          signInData.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          signInData.email.length - lastDotPos > 2
        )
      ) {
        Fromvlid = false;
        errors["email"] = "Please enter valid Email ID";
      }
    }

    if (!signInData.password?.trim()) {
      Fromvlid = false;
      errors["password"] = "Password is required*";
    }else{

      if(checkPasswordValidation(signInData.password)!==null){
        Fromvlid = false;
        errors["password"] = checkPasswordValidation(signInData.password);
      }
    }
    setErrors(errors);
    return Fromvlid;
  };

  const handleSignup = () => {
    if (!validForm()) return;
    if (signInData?.rememberMe) {
      localStorage.setItem("rememberMe", "true");
      localStorage.setItem("email", signInData?.email);
      localStorage.setItem("password", signInData?.password);
  } else {
      localStorage.removeItem("rememberMe");
      localStorage.removeItem("email");
      localStorage.removeItem("password");
  }
    setLoading(true)
    dispatch(signIn(signInData))
      .unwrap()
      .then((res) => {
        if (!res?.data?.success) {
          setLoading(false)
          return};
        if (res?.data?.error === "EMAIL_NOT_VERIFIED") {
          setLoading(false)
          navigate(`/verify-email/${res?.data?.data}`);
        }
      });
  };

  

  return (
    <div className="auth-layout">
      <div className="box">
        <Authheader />
        <div className="auth-body-alignment">
          <div className="grid">
            <div className="grid-items">
              <div className="image">
                <img src={AuthVector} alt="AuthVector" />
              </div>
            </div>
            <div className="grid-items">
              <div className="input-bottom-alignment">
                <Input
                  name={"email"}
                  label="Email"
                  placeholder="Enter Your Email"
                  onChange={handleOnChange}
                  error={error?.email}
                  value={signInData?.email}
                />
              </div>
              <Input
                type={showEyeIcon ? "text" : "password"}
                label="Password"
                name={"password"}
                placeholder="Enter Password"
                icon={showEyeIcon?HideEyeIcon:EyeIcon}
                onChange={handleOnChange}
                error={error?.password}
                iconClick={() => setShowEyeIcon(!showEyeIcon)}
                value={signInData?.password}
              />
              <div className="checkbox-forgotpassword-content-alignment">
                <div className="checkbox-text-alignment">
                  <input type="checkbox" id="Donuts" name="rememberMe" checked={signInData?.rememberMe} onChange={handleOnChange}/>
                  <label htmlFor="Donuts">
                    {" "}
                    <span>Remember me</span>
                  </label>
                </div>
                <NavLink to="/forgot-pasword">Forgot Password?</NavLink>
              </div>
              <div className="login-button-alignment">
                <Button text="Sign In" onClick={handleSignup} loader={loading}  />
              </div>
              <div className="text-grid">
                <div className="line"></div>
                <span>OR</span>
                <div className="line"></div>
              </div>
              <div className="google-contnet-alignment">
                <Loginwithgoogle />
              </div>
              <div className="last-text">
                <span>
                  Don’t have an account ?{" "}
                  <NavLink to="/signup">Sign up</NavLink>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
