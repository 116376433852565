import React from "react";
import Authheader from "../../components/authheader";
import AuthVector from "../../assets/icons/auth-vector.svg";
import EyeIcon from "../../assets/icons/eye.svg";
import HideEyeIcon from "../../assets/icons/hideeye.svg";
import Input from "../../components/input";
import Button from "../../components/button";
import Loginwithgoogle from "../../components/loginwithgoogle";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPassword, forgotPassword, signUp, verifyOtp } from "../../store/slices/authSlice";
import { useState } from "react";
import toast from "react-hot-toast";
import { checkPasswordValidation } from "../../common/validation";
export default function ChangePassword() {
const [forgotInfo,setForgotInfo]=useState({})
const [errors,setErrors]=useState({})
const [loading,setLoading]=useState(false)
const [showEyeIcon, setShowEyeIcon] = useState([
  { show: false },
  { show: false }
]);
  const {token}=useParams()
  const dispatch=useDispatch();
  const navigate=useNavigate();

  const handleOnChange=(e)=>{
  const {name,value}= e.target;
  setForgotInfo({...forgotInfo,[name]:value});
setErrors({...errors,[name]:''})  
} 

  const validForm = () => {
    let Fromvlid = true;
    let errors = {};
    if (!forgotInfo.new_password?.trim()) {
      Fromvlid = false;
      errors["new_password"] = "New Password is required*";
    }
    if (!forgotInfo.confirm_password?.trim()) {
      Fromvlid = false;
      errors["confirm_password"] = "Confirm Password is required*";
    }
    if (forgotInfo.new_password?.trim() !== forgotInfo.confirm_password?.trim()) {
      Fromvlid = false;
      errors["confirm_password"] = "Password does not match with new password*";
    }else{

      if (checkPasswordValidation(forgotInfo.new_password) !== null) {
        Fromvlid = false;
        errors["confirm_password"] = checkPasswordValidation(forgotInfo.new_password);
      }
    }
    setErrors(errors);
    return Fromvlid;
  };
const handleSignup=()=>{
  if(!validForm()) return;
  setLoading(true)
  const body={
    token:token,
  ...forgotInfo
  }
  dispatch(resetPassword(body)).unwrap().then((res)=>{
    if(!res?.data?.success) {
      setLoading(false)
      return};
      setLoading(false)
    toast.success(res?.data?.message)
    navigate("/")
  });
}
const handleShowEyeIcon = (index) => {
  let value = [...showEyeIcon];
  value[index].show = !value[index].show;
  setShowEyeIcon(value);
};
  return (
    <div className="auth-layout">
      <div className="box">
        <Authheader />
        <div className="auth-body-alignment">
          <div className="grid">
            <div className="grid-items">
              <div className="image">
                <img src={AuthVector} alt="AuthVector" />
              </div>
            </div>
            <div className="grid-items">
              <div className="input-bottom-alignment">
        
                <Input
                type={showEyeIcon[0]?.show ? "text" : "password"}
                label="New Password"
                name={"new_password"}
                placeholder="Enter New Password"
                value={forgotInfo?.new_password}
                icon={showEyeIcon[0]?.show ?HideEyeIcon:EyeIcon}
                onChange={handleOnChange}
                error={errors?.new_password}
                iconClick={() => handleShowEyeIcon(0)}
              />
              </div>
              <div className="input-bottom-alignment">
              <Input
                type={showEyeIcon[1]?.show ? "text" : "password"}
                label="Confirm Password"
                name={"confirm_password"}
                value={forgotInfo?.confirm_password}
                placeholder="Enter Confirm Password"
                onChange={handleOnChange}
                icon={showEyeIcon[1]?.show ?HideEyeIcon:EyeIcon}
                error={errors?.confirm_password}
                iconClick={() => handleShowEyeIcon(1)}
              />
            </div>
              <div className="signup-button-alignment">
                {/* <NavLink to="/dashboard"> */}
                <Button text="Change Password" onClick={handleSignup} loader={loading} />
                
                {/* </NavLink> */}
              </div>
              <div className="text-grid">
                <div className="line"></div>
                <span>OR</span>
                <div className="line"></div>
              </div>
              <div className="google-contnet-alignment">
                <Loginwithgoogle />
              </div>
              <div className="last-text">
                <span>
                  You have an account ? <NavLink to="/login">Sign In</NavLink>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
