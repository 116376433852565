import React from "react";
import "./conformation.scss";
import Closeicon from "../common/closeicon";
export default function Conformation({title,onClose,onClick,message,btnText,icon,isModal}) {
  return (
    <div>
      <div className={`conformation-modal-wrapper ${isModal&&'openModalWrapper'}`}>
        <div className="modal-md">
          <div className="modal-header">
            <p>{title}</p>
            <Closeicon onClick={onClose}/>
          </div>
          <div className="modal-body">
            <div className="center-icon-alignment">
              <img src={icon} alt="LogoutIcon" />
            </div>
            <p>
         
              {message  }
            </p>
            <div className="btn-center-alignment">
                <button onClick={()=>onClick&&onClick()}>{btnText}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
