import React from 'react'
import './statsinformation.scss';
import Userinformation from '../../dashboard/userinformation';
import { useSelector } from 'react-redux';
export default function Statsinformation() {
  const {focusedProject}=useSelector((state)=>state.project)
  return (
    <div>
       <div className="container">
      <div className="stats-information-all-contnet-alignment">
        <div className="text-icon">
          <span>{focusedProject?.bot_name}</span>
        </div>
        <Userinformation/>
      </div>
    </div>
    </div>
  )
}
