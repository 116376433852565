import React from "react";
import "./input.scss";
export default function Input({
  type,
  label,
  placeholder,
  switchtoogle,
  icon,
  onChange,
  name,
  error,
  value,
  iconClick,
  disabled,
  handleVisibility,
  isSwitchTrue,
  isPointerNone,
  limit,
  copiedUrl,
}) {
  return (
    <div className="input">
      <div className={switchtoogle ? "toogle-alignment" : ""}>
        <label>{label}</label>
        {switchtoogle && (
          <div className="switch-design">
            <label class="switch">
              <input
                type="checkbox"
                checked={isSwitchTrue && isSwitchTrue}
                onClick={() => handleVisibility && handleVisibility()}
              />
              <span class="slider round"></span>
            </label>
          </div>
        )}
      </div>
      <div className="relative-div">
        <input
          style={{
            pointerEvents: isPointerNone ? "none" : "all",
            opacity: isPointerNone ? 0.5 : 1,
          }}
          type={type || "text"}
          disabled={disabled || false}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={(e) => {
            onChange && onChange(e);
          }}
          maxLength={limit && limit}
        />
        {icon && (
          <div className="icon" onClick={iconClick && iconClick}>
            {copiedUrl ? (
              <p style={{ color: "white", fontSize: "10px" }}>Copied!</p>
            ) : (
              <img src={icon} alt="icons" />
            )}
          </div>
        )}
      </div>
      {error && <span style={{ color: "red", fontSize: "10px" }}>{error}</span>}
    </div>
  );
}
