export const checkPasswordValidation = (value) => {
  const isWhitespace = /^(?=.*\s)/;
  if (isWhitespace.test(value)) {
    return "Password must not contain Whitespaces.";
  }

  const isContainsUppercase = /^(?=.*[A-Z])/;
  if (!isContainsUppercase.test(value)) {
    return "Password must have at least one Uppercase Character.";
  }

  const isContainsLowercase = /^(?=.*[a-z])/;
  if (!isContainsLowercase.test(value)) {
    return "Password must have at least one Lowercase Character.";
  }

  const isContainsNumber = /^(?=.*[0-9])/;
  if (!isContainsNumber.test(value)) {
    return "Password must contain at least one Digit.";
  }

  const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/;
  if (!isContainsSymbol.test(value)) {
    return "Password must contain at least one Special Symbol.";
  }

  const isValidLength = /^.{8,16}$/;
  if (!isValidLength.test(value)) {
    return "Password must be 8-16 Characters Long.";
  }

  return null;
};

export const hexToRgb = hex =>
  hex
    ?.replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));


 export const  base64ToBlob=(base64String, contentType = 'image/jpeg')=> {
      // Remove the data URI scheme from the base64 string
      const base64WithoutScheme = base64String?.replace(/^data:[^;]+;base64,/, '');
    
      // Convert the base64 string to a byte array
      const byteCharacters = atob(base64WithoutScheme);
    
      // Create an array buffer from the byte array
      const arrayBuffer = new ArrayBuffer(byteCharacters.length);
      const uint8Array = new Uint8Array(arrayBuffer);
    
      for (let i = 0; i < byteCharacters.length; i++) {
        uint8Array[i] = byteCharacters.charCodeAt(i);
      }
    
      // Create a Blob from the array buffer
      return new Blob([arrayBuffer], { type: contentType });
    }
  
    export  const newShade = (hexColor, magnitude) => {
      hexColor = hexColor?.replace(`#`, ``);
      if (hexColor?.length === 6) {
        const decimalColor = parseInt(hexColor, 16);
        let r = (decimalColor >> 16) + magnitude;
        r > 255 && (r = 255);
        r < 0 && (r = 0);
        let g = (decimalColor & 0x0000ff) + magnitude;
        g > 255 && (g = 255);
        g < 0 && (g = 0);
        let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
        b > 255 && (b = 255);
        b < 0 && (b = 0);
        return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
      } else {
        return hexColor;
      }
    };