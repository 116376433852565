import React from "react";
import "./myproject.scss";
import LightIcon from "../../../assets/icons/light.svg";
import Userinformation from "../userinformation";
import { useLocation } from "react-router-dom";
export default function Myproject() {

const {pathname}=useLocation()
  return (
    <div className="container">
      <div className="my-project-all-contnet-alignment">
        <div className="text-icon">
          <img src={LightIcon} alt="LightIcon" />
          <span>{pathname.includes("settings")?"My Profile":"My Projects"}</span>
        </div>
        <Userinformation/>
      </div>
    </div>
  );
}
