import React, { useRef, useState } from "react";
import "./myprojectcard.scss";
import MenuIcon from "../../../assets/icons/menu.svg";
import DocumentIcon from "../../../assets/icons/document.svg";
import EditIcon from "../../../assets/icons/edit.svg";
import RemoveIcon from "../../../assets/icons/remove-md.svg";
import { useNavigate } from "react-router-dom";
import Createchatbot from "../../../components/modal/createchatbot";
import { useDispatch, useSelector } from "react-redux";
import AnimatedSection from "../../../common/animatedsection";
import {
  deleteProject,
  setFocusedProject,
  setSelectedProject,
} from "../../../store/slices/projectSlice";
import useOnClickOutside from "../../../common/Hooks/useOnClickOutside";
import Conformation from "../../../components/modal/conformation";
import toast from "react-hot-toast";
import DeleteIcon from "../../../assets/icons/delete-icons.svg";
import UpdateIcon from "../../../assets/icons/update.svg";

export default function Myprojectcard() {
  const { project } = useSelector((state) => state.project);
  const { selectedPlan } = useSelector((state) => state.subscription);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editRef = useRef();
  const [dropdown, setDropdown] = useState(null);
  const [createchatbotmodal, setCreatechatbotmodal] = useState(false);
  const [isEditProject, setIsEditProject] = useState(false);
  const [editedProjectData, sertEditedProjectData] = useState({});
  const [isDeleteModel, setIsDeleteModel] = useState(false);
  const [deletedData, setDeletedData] = useState({});

  useOnClickOutside(editRef, () => setDropdown(null));

  let duration = 200;
  const handleOnClickCard = (data) => {
    navigate(`/project/stats/${data?.id}`);
    dispatch(setFocusedProject(data));
    dispatch(setSelectedProject(data));
  };
  const handleEditModel = (e, index) => {
    e.stopPropagation();
    if (index === dropdown) {
      setDropdown(null);
    } else {
      setDropdown(index);
    }
  };
  const handleDeleteProject = (data) => {
    let body = {
      id: data?.id,
    };
    dispatch(deleteProject(body))
      .unwrap()
      .then((res) => {
        if (!res?.data?.success) {
          toast.error(res?.data?.message);
          return;
        }
        setIsDeleteModel(!isDeleteModel);
        toast.success(res?.data?.message);
      });
  };

  const handleEditProject = (e, data) => {
    e.stopPropagation();
    sertEditedProjectData(data);
    setIsEditProject(!isEditProject);
  };

  const handleIsDelete = (e, item) => {
    e.stopPropagation();
    setDeletedData(item);
    setIsDeleteModel(true);
  };
  return (
    <>
      <div className="my-project-card-all-contnet-alignment">
        <div className="container">
          <div className="grid">
            <AnimatedSection animationType="fade-up" duration={500} delay={100}>
              <div className="grid-items">
                {/* <NavLink to="/project/stats"> */}
                <div onClick={() => setCreatechatbotmodal(!createchatbotmodal)}>
                  <div className="image-center">
                    <img src={DocumentIcon} alt="DocumentIcon" />
                  </div>
                  <span>Add New Project</span>
                </div>
                {/* </NavLink> */}
              </div>
            </AnimatedSection>
            {project?.length > 0 &&
              project?.map((item, index) => {
                duration = duration + (index ? 300 : 0);

                return (
                  <AnimatedSection
                    animationType="fade-up"
                    duration={500}
                    delay={duration}
                  >
                    <div
                      className="grid-items"
                      onClick={() => handleOnClickCard(item)}
                    >
                      <div className="title-icon-align">
                        <span>{item?.company_name}</span>
                        <div className="relative-div" ref={editRef}>
                          <img
                            onClick={(e) => handleEditModel(e, index)}
                            src={MenuIcon}
                            alt="MenuIcon"
                          />
                          <div
                            className={
                              dropdown === index
                                ? "dropdown-design show"
                                : "dropdown-design hide"
                            }
                          >
                            <div className="design">
                              <div
                                className="new-icon-text"
                                onClick={(e) => handleEditProject(e, item)}
                              >
                                <img src={EditIcon} alt="EditIcon" />
                                <p>Edit</p>
                              </div>
                              <div
                                className="new-icon-text"
                                onClick={(e) => handleIsDelete(e, item)}
                              >
                                <img src={RemoveIcon} alt="RemoveIcon" />
                                <p>Delete</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text">
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  </AnimatedSection>
                );
              })}
          </div>
        </div>
      </div>
      {(createchatbotmodal &&
        project?.length < Number(selectedPlan?.chatbot)) ||
      isEditProject ? (
        <Createchatbot
          createchatbotmodal={createchatbotmodal}
          setCreatechatbotmodal={setCreatechatbotmodal}
          isEditProject={isEditProject}
          setIsEditProject={setIsEditProject}
          editedProjectData={editedProjectData}
        />
      ) : (
        <Conformation
          title={"Upgrade Your Plan"}
          onClose={() => {
            setCreatechatbotmodal(false);
            setIsEditProject(false);
          }}
          onClick={() => navigate("/settings/subscription")}
          message={"Please Upgrade Your DhiBot Subscription Plan."}
          btnText={"Upgrade"}
          icon={UpdateIcon}
          isModal={createchatbotmodal || isEditProject}
        />
      )}

      <Conformation
        title={"Delete"}
        onClose={() => setIsDeleteModel(!isDeleteModel)}
        onClick={() => handleDeleteProject(deletedData)}
        message={`Are you sure, you want to delete ${deletedData?.company_name} project?`}
        btnText={"Delete"}
        icon={DeleteIcon}
        isModal={isDeleteModel}
      />
    </>
  );
}
