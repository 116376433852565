import React from "react";
import "./loginwithgoogle.scss";
import GoogleIcon from "../../assets/icons/google.svg";
import { useSignInWithGoogle } from "react-firebase-hooks/auth";
import firebase from "./firebase";
import { useDispatch } from "react-redux";
import { googleLogin } from "../../store/slices/authSlice";
import { useEffect } from "react";
import toast from "react-hot-toast";
export default function Loginwithgoogle() {
  const [signInWithGoogle, user] = useSignInWithGoogle(firebase);
  const dispatch = useDispatch();
  useEffect(()=>{
    if(user?.user?.email){
      handleSignin(user)
    }
  },[user])
  const handleSignin = (data) => {
    const body = {
      name: data?.user?.displayName,
      email: data?.user?.email,
      is_social: true,
      accessToken:data?.user?.accessToken
    };

    dispatch(googleLogin(body)).unwrap().then((res)=>{
      if(!res?.data?.success){ return}
      toast.success(res?.data?.message)
    });
  };

  return (
    <div className="login-with-google">
      <button onClick={() => signInWithGoogle()}>
        <img src={GoogleIcon} alt="GoogleIcon" />
        <span>Google</span>
      </button>
    </div>
  );
}
