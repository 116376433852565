import React, { useEffect, useState } from "react";
import "./createchatbot.scss";
import Closeicon from "../common/closeicon";
import Input from "../../input";
import Button from "../../button";
import Textarea from "../../textarea";
import { useDispatch } from "react-redux";

import toast from "react-hot-toast";
import { createProject, editProject } from "../../../store/slices/projectSlice";
export default function Createchatbot(props) {
  const {
    setCreatechatbotmodal,
    createchatbotmodal,
    isEditProject,
    setIsEditProject,
    editedProjectData,
  } = props;
  const dispatch = useDispatch();
  const [chatBotInfo, setChatBotInfo] = useState({});
  const [errors,setErrors]=useState({})
  const [loading,setLoading]=useState(false)

  useEffect(() => {
    if (isEditProject) {
      setChatBotInfo({
        company_name: editedProjectData?.company_name,
        bot_name: editedProjectData?.bot_name,
        description: editedProjectData?.description,
      });
    }
  }, [isEditProject]);
  const validForm = () => {
    let Fromvlid = true;
    let errors = {};
    if (!chatBotInfo.company_name?.trim()) {
      Fromvlid = false;
      errors["company_name"] = "Please enter Company name";
    }
    if (!chatBotInfo.bot_name?.trim()) {
      Fromvlid = false;
      errors["bot_name"] = "Please enter Bot name*";
    }
    if (!chatBotInfo.description?.trim()) {
      Fromvlid = false;
      errors["description"] = "Please enter Description*";
    }
    setErrors(errors);
    return Fromvlid;
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setChatBotInfo({ ...chatBotInfo, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true)
    if(!validForm()) return;
    let body = {
      ...chatBotInfo,
    };
    if (isEditProject) {
      body = {
        ...body,
        id: editedProjectData?.id,
      };
      dispatch(editProject(body))
        .unwrap()
        .then((res) => {
          if (res?.status != 200) {
            toast.error(res?.data?.message);
            return;
          }
          toast.success(res?.data?.message);
          handleOnClodeModal();
        });
    } else {
      dispatch(createProject(body))
        .unwrap()
        .then((res) => {
          if (res?.status != 200) {
            toast.error(res?.data?.message);
            return;
          }
          toast.success(res?.data?.message);
          handleOnClodeModal();
       
        });
        setLoading(false)
    }
  };
  const handleOnClodeModal = () => {
    setChatBotInfo({});
    setErrors({})
    if (setIsEditProject) {
      setIsEditProject(false);
    }

    if (setCreatechatbotmodal) {
      setCreatechatbotmodal(false);
    }

  };
  return (
    <div>
      <div
        className={`modal-wrapper ${
          createchatbotmodal || isEditProject ? "openModalWrapper" : ""
        }`}
      >
        <div className="modal">
          <div className="modal-header">
            <p>{isEditProject ? "Edit Chat Bot" : "Create Chat Bot"}</p>
            <Closeicon onClick={() => handleOnClodeModal()} />
          </div>
          <div className="modal-body">
            <div className="input-bottom-alignment">
              <Input
                label="Company Name"
                name={"company_name"}
                placeholder="Enter Your Company Name"
                value={chatBotInfo?.company_name}
                onChange={handleOnChange}
                error={errors?.company_name}
              />
            </div>
            <div className="input-bottom-alignment">
              <Textarea
                label="Company Details"
                name={"description"}
                value={chatBotInfo?.description}
                placeholder="Enter Your Company Details"
                onChange={handleOnChange}
                error={errors?.description}
              />
            </div>
            <div className="input-bottom-alignment">
              <Input
                label="Chat Bot Name"
                name={"bot_name"}
                placeholder="Enter Your Chat Bot Name"
                value={chatBotInfo?.bot_name}
                onChange={handleOnChange}
                error={errors?.bot_name}
              />
            </div>
        
            <div className="button-right-alignment">
              <Button
                text={isEditProject ? "Update" : "Create"}
                onClick={handleSubmit}
                loader={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
