import React from 'react'
import './stats.scss';
import Statsinformation from './statsinformation';
import Arrow from '../../assets/icons/arrow.svg';
import Statstab from './statstab';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
export default function Stats() {
  const {focusedProject}=useSelector((state)=>state.project)
  const navigate=useNavigate()
  return (
    <div>
        <div className='stats-breadcumb'>
            <div className='container'>
                <div className='alignment'>
                <span onClick={()=>navigate("/")}>All Projects </span>
                <img src={Arrow} alt="Arrow"/>
                <span className='active'>{focusedProject?.bot_name}</span>
                </div>
            </div>
        </div>
      <Statsinformation/>
      <Statstab/>
    </div>
  )
}
