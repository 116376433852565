import React from 'react'
import './tab.scss';
export default function Tab({setIsCompany,isCompany}) {
  return (
    <div className='tab-design'>
      <button className={!isCompany&&'active'} onClick={()=>setIsCompany(false)}>Individual</button>
      <button className={isCompany&&'active'} onClick={()=>setIsCompany(true)}>Company</button>
    </div>
  )
}
