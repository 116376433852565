import React, { useState } from 'react'
import './noprojectsection.scss';
import NotFoundImage from '../../../assets/images/no-project.png';
import Button from '../../../components/button';
import Createchatbot from '../../../components/modal/createchatbot';
export default function NoprojectSection() {
  const [createchatbotmodal , setCreatechatbotmodal ] = useState(false);
  return (
    <>
    <div className='no-project-section-contnet-alignment'>
      <div className='box'>
        <div className='center-image'>
            <img src={NotFoundImage} alt="NotFoundImage"/>
        </div>
        <h4>No projects Yet</h4>
        <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi feugiat sem at ligula varius maximus.
        </p>
        <div className='button-center-alignment' onClick={()=> setCreatechatbotmodal(!createchatbotmodal)}>
            <Button text="+ Add New Project" />
        </div>
      </div>
    </div>
    <Createchatbot createchatbotmodal={createchatbotmodal} setCreatechatbotmodal={setCreatechatbotmodal} />
    </>
  )
}
