import React, { useEffect } from "react";
import "./information.scss";
import Input from "../../../components/input";
import Button from "../../../components/button";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { updateUser } from "../../../store/slices/authSlice";
import Changepassword from "../../../components/modal/changepassword";
import { getSubscription } from "../../../store/slices/planSlice";
import { getUser } from "../../../helpers/utils/auth.util";
import toast from "react-hot-toast";
export default function Information() {
  // const { user } = useSelector((state) => state.auth);
  const user=getUser()
const dispatch=useDispatch()
  const [userInfo,setUserInfo]=useState(user)
  const [loading,setLoading]=useState(false)
  const [changePasswordModel,setChangePasswordModel]=useState(false)
  useEffect(()=>{
    dispatch(getSubscription())
  },[])
const handleOnChange=(e)=>{
  const {name,value}=e.target;
  setUserInfo({...userInfo,[name]:value})
}
const handleSubmit=()=>{
  setLoading(true)
  const body={
    ...userInfo
  }
  dispatch(updateUser(body)).unwrap().then((res)=>{
    if(!res?.data?.success){
      toast.error(res?.data?.message)
      return;
    }
    setLoading(false)
    toast.success(res?.data?.message)
  })
}
  return (
    <div>
      <div className="container">
        <div className="setting-information-content-alignment">
          <div className="grid">
            <div className="grid-items">
              <div className="card-header">
                <span>Personal Information</span>
              </div>
              <div className="card-body">
                <Input label="Full Name" name={"name"} placeholder={userInfo?.name} value={userInfo?.name} onChange={handleOnChange}/>
                <div className="save-button">
                  <Button text="Save Changes" onClick={handleSubmit} loader={loading} />
                </div>
              </div>
            </div>
            <div className="grid-items">
              <div className="card-header">
                <span>Account Information</span>
              </div>
              <div className="card-body">
                <Input
                  label="Email"
                  placeholder={userInfo?.email}
                  value={userInfo?.email}
                  name={"email"}
               
                  disabled={true}
                />
                <div className="change-password" onClick={()=>setChangePasswordModel(true)}>
                    <a>Change password</a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
        {changePasswordModel&&<Changepassword changePasswordModel={changePasswordModel} setChangePasswordModel={setChangePasswordModel}/>}
    
    </div>
  );
}
