import './App.css';
import './styles/mixins/index.scss';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { RouterProvider } from 'react-router-dom';
import router from './routeWrappers/routes';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
   <>
      <Toaster containerStyle={{
            zIndex: "99999999999999"
          }} position="top-center" reverseOrder={false} />
    <RouterProvider router={router} />
   </>
  );
}

export default App;
