import React, { useState } from "react";
import "./statstab.scss";
import Statsdetails from "../statsdetails";
import Chathistory from "../chathistory";
import Knowledgesources from "../knowledgesources";
import Chatbotsettings from "../chatbotsettings";
import Embeddingscript from "../embeddingscript";
import { useNavigate, useParams } from "react-router-dom";
import AnimatedSection from "../../../common/animatedsection";
import Createknowledgesource from "../../../components/modal/createknowledgesource";
import { deleteFile, getUploadedFile } from "../../../store/slices/embeddingSlice";
import { useDispatch } from "react-redux";
import Conformation from "../../../components/modal/conformation";
import DeleteIcon from "../../../assets/icons/delete-icons.svg"
import toast from "react-hot-toast";

export default function Statstab() {
  const { route, projectId } = useParams();
  const [createknowledgesource, setCreateknowledgesource] = useState(false);
  const [deleteKnowledgeSource, setDeleteKnowledgeSource] = useState(false);
  const [deletedData,setDeletedData]=useState({})
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleNavigate = (data) => {
    navigate(`/project/${data}/${projectId}`);
  };
  const getAllUploadFile = () => {
    let body = {
      offset: 0,
      project_id: projectId,
    };
    dispatch(getUploadedFile(body));
  };
  const handleDeleteFile = (data) => {

    let body = {
      id: data?.id,
    };
    dispatch(deleteFile(body))
      .unwrap()
      .then((res) => {
        if (!res?.data?.success) {
          toast.error(res?.data?.message);
          return;
        }
        toast.success(res?.data?.message);
        setDeleteKnowledgeSource(false)
      });
  };
  return (
    <>
      <div className="container">
        <div className="statstab-all-contnet-alignment">
          <a
            className={route === "stats" && "active"}
            onClick={() => {
              handleNavigate("stats");
            }}
          >
            Stats
          </a>
          <a
            className={route === "chat-history" && "active"}
            onClick={() => {
              handleNavigate("chat-history");
            }}
          >
            Chat History
          </a>
          <a
            className={route === "knowledge-sources" && "active"}
            onClick={() => {
              handleNavigate("knowledge-sources");
            }}
          >
            Knowledge Sources
          </a>
          <a
            className={route === "chatbot-settings" && "active"}
            onClick={() => {
              handleNavigate("chatbot-settings");
            }}
          >
            Chatbot Settings
          </a>
          <a
            className={route === "enbedding-script" && "active"}
            onClick={() => {
              handleNavigate("enbedding-script");
            }}
          >
            Embedding Script
          </a>
          {/* <a
            className={route == "integration" && "active"}
            onClick={() => {
              handleNavigate("integration");
            }}
          >
            Integrations
          </a> */}
        </div>
      </div>
      <div className="container">
        <div className="tab-all-information-content-alignment">
          {route === "stats" && (
            <AnimatedSection animationType="fade-up" duration={500} delay={100}>
              <Statsdetails />
            </AnimatedSection>
          )}
          {route === "chat-history" && (
            <AnimatedSection animationType="fade-up" duration={500} delay={100}>
              <Chathistory />
            </AnimatedSection>
          )}
          {route === "knowledge-sources" && (
            <AnimatedSection animationType="fade-up" duration={500} delay={100}>
              <Knowledgesources
                createknowledgesource={createknowledgesource}
                setCreateknowledgesource={setCreateknowledgesource}
                deleteKnowledgeSource={deleteKnowledgeSource}
                setDeleteKnowledgeSource={setDeleteKnowledgeSource}
                deletedData={deletedData} setDeletedData={setDeletedData}
              />
            </AnimatedSection>
          )}
          {route === "chatbot-settings" && (
            <AnimatedSection animationType="fade-up" duration={500} delay={100}>
              <Chatbotsettings />
            </AnimatedSection>
          )}
          {route === "enbedding-script" && (
            <AnimatedSection animationType="fade-up" duration={500} delay={100}>
              <Embeddingscript />
            </AnimatedSection>
          )}
          {/* {route === "integration" && (
            <AnimatedSection animationType="fade-up" duration={500} delay={100}>
              <Integrations />
            </AnimatedSection>
          )} */}
        </div>
      </div>
      <Createknowledgesource
        createknowledgesource={createknowledgesource}
        setCreateknowledgesource={setCreateknowledgesource}
        getAllUploadFile={getAllUploadFile}
      />
      <Conformation isModal={deleteKnowledgeSource} 
      title={"Delete"}
      onClose={() => setDeleteKnowledgeSource(!deleteKnowledgeSource)}
      onClick={() => handleDeleteFile(deletedData)}
      message={`Are you sure, you want to delete ${deletedData?.input_file_name} project?`}
      btnText={"Delete"}
      icon={DeleteIcon}

      />
    </>
  );
}
