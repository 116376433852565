import React, { useState } from "react";
import "./subscription.scss";
import TrueIcon from "../../../assets/icons/true.svg";
import Button from "../../../components/button";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { createPayment, getPlan } from "../../../store/slices/planSlice";
import toast from "react-hot-toast";
import Contactus from "../../../components/modal/contactus";
export default function Subscription() {
  const { selectedPlan, plans } = useSelector((state) => state.subscription);
  const [contactUpModal, setContactUsModal] = useState(false);
  const [togglePlans, setTogglePlans] = useState(false);
  const [loading, setLoading] = useState(false);
  const [upgradePlan,setUpgradePlan]=useState(null)
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    purpose: "",
    phone_number: null,
  });
  const [error, setErrors] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPlan());
  }, []);

  const handleOnSubmit = (id,index) => {
    setUpgradePlan(index)
    setLoading(true);
    dispatch(createPayment(id))
      .unwrap()
      .then((res) => {
        if (!res?.data?.success) {
          toast.error(res?.data?.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        window.open(res?.data?.data?.session_url, "_self");
      });
  };

  const handleModalClose = () => {
    setErrors({});
    setUserData({
      name: "",
      email: "",
      purpose: "",
      phone_number: null,
    });
    setContactUsModal(false);
  };

  return (
    <div className="container">
      <div className="subscription-all-details-contnet-alignment">
        <div className="title">
          <p>Plan & Pricing</p>
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
            feugiat sem at ligula varius maximus.
          </span>
        </div>
        <div className="plan-validity-all-contnet-alignment">
          <p>Monthly</p>
          <label class="switch">
            <input
              type="checkbox"
              checked={togglePlans}
              onClick={() => setTogglePlans(!togglePlans)}
            />
            <span class="slider round"></span>
          </label>
          <p>Yearly</p>
        </div>
        <div className="update-plan-grid-design">
          {plans?.length > 0 &&
            plans
              ?.filter((data) =>
                togglePlans
                  ? data?.interval === "annual"
                  : data?.interval === "monthly"
              )
              .sort((a, b) => a.amount - b.amount)
              ?.map((item,index) => {
                return (
                  <div
                    className={`prize-box ${
                      item?.id === selectedPlan?.subscription_plan_id &&
                      "active"
                    }`}
                  >
                    <div className="heihgt-full">
                      <div className="prize">
                        <svg
                          width="122"
                          height="123"
                          viewBox="0 0 122 123"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19.0001 0L9.15527e-05 19H19.0001V0Z"
                            fill={
                              item?.id === selectedPlan?.subscription_plan_id
                                ? "#D4D4D4"
                                : "#227B43"
                            }
                          />
                          <path
                            d="M19.0001 93.7143V0H122V93.7143L66.9887 123L19.0001 93.7143Z"
                            fill={
                              item?.id === selectedPlan?.subscription_plan_id
                                ? "white"
                                : "url(#paint0_linear_1_2)"
                            }
                          />

                          <text
                            x="56%"
                            y="50%"
                            text-anchor="middle"
                            dominant-baseline="middle"
                            font-size="24"
                            fill={
                              item?.id === selectedPlan?.subscription_plan_id
                                ? "#22C55E"
                                : "white"
                            }
                            fontWeight="600"
                          >
                            {item?.amount === 0 ? "Free" : `$${item?.amount}`}
                          </text>
                          <defs>
                            <linearGradient
                              id="paint0_linear_1_2"
                              x1="21.5246"
                              y1="-25.8301"
                              x2="137.389"
                              y2="-17.1242"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#22C55E" />
                              <stop offset="1" stop-color="#115E59" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                      <h4>
                        {item?.name?.split(" ")[0]}
                        <br />
                        {item?.name?.split(" ")[1]}
                      </h4>
                      {item?.features?.length > 0 &&
                        item?.features?.map((data) => {
                          return (
                            <div className="icon-grid">
                              <img src={TrueIcon} alt="TrueIcon" />
                              <span>{data}</span>
                            </div>
                          );
                        })}
                    </div>
                    <Button
                      text={
                        item?.id === selectedPlan?.subscription_plan_id
                          ? "Activated"
                          : "Choose plan"
                      }
                      onClick={() => {
                        item?.id !== selectedPlan?.subscription_plan_id&& handleOnSubmit(item?.id,index);
                      }}
                      loader={upgradePlan==index&&loading}

                    />
                  </div>
                );
              })}
          <div className={`prize-box`}>
            <div className="heihgt-full">
              <div className="prize">
                {/* <svg
                          width="122"
                          height="123"
                          viewBox="0 0 122 123"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19.0001 0L9.15527e-05 19H19.0001V0Z"
                            fill={
                              item?.id === selectedPlan?.subscription_plan_id
                                ? "#D4D4D4"
                                : "#227B43"
                            }
                          />
                          <path
                            d="M19.0001 93.7143V0H122V93.7143L66.9887 123L19.0001 93.7143Z"
                            fill={
                              item?.id === selectedPlan?.subscription_plan_id
                                ? "white"
                                : "url(#paint0_linear_1_2)"
                            }
                          />

                          <text
                            x="56%"
                            y="50%"
                            text-anchor="middle"
                            dominant-baseline="middle"
                            font-size="24"
                            fill={
                              item?.id === selectedPlan?.subscription_plan_id
                                ? "#22C55E"
                                : "white"
                            }
                            fontWeight="600"
                          >
                            {item?.amount == 0 ? "Free" : `$${item?.amount}`}
                          </text>
                          <defs>
                            <linearGradient
                              id="paint0_linear_1_2"
                              x1="21.5246"
                              y1="-25.8301"
                              x2="137.389"
                              y2="-17.1242"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#22C55E" />
                              <stop offset="1" stop-color="#115E59" />
                            </linearGradient>
                          </defs>
                        </svg> */}
              </div>
              <h4>Enterprise</h4>

              <div className="icon-grid">
                <img src={TrueIcon} alt="TrueIcon" />
                <span>Contact Us for Custom Solutions</span>
              </div>
            </div>
            <Button
              text={"Contact Us"}
              onClick={() => {
                setContactUsModal(true);
              }}
             
            />
          </div>
        </div>
      </div>
      <Contactus
        onClose={handleModalClose}
        isModal={contactUpModal}
        error={error}
        setErrors={setErrors}
        userData={userData}
        setUserData={setUserData}
      />
    </div>
  );
}
