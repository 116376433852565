import React, { useEffect } from "react";
import "./chatbotdetails.scss";
import Tab from "../tab";
import Input from "../../../../components/input";
import Chooseimage from "../chooseimage";
import Button from "../../../../components/button";
import CopyIcon from "../../../../assets/icons/copy.svg";
import ReloadIcon from "../../../../assets/icons/reload.svg";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  changeProjectVisibility,
  refreshApiKey,
  setFocusedProject,
  setSelectedProject,
  setUpdateSelectedProject,
  updateBrand,
} from "../../../../store/slices/projectSlice";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import ImageInput from "../../../../common/file/ImageInput";
import { base64ToBlob } from "../../../../common/validation";
import Conformation from "../../../../components/modal/conformation";
import UpdateIcon from "../../../../assets/icons/update.svg";
import { getUser } from "../../../../helpers/utils/auth.util";

export default function Chatbotdetails({ isKnowledgeChat }) {
  const { selectedProject, focusedProject } = useSelector(
    (state) => state.project
  );
  const user = getUser();
  useEffect(() => {
    if (
      focusedProject?.gdpr_questions == null ||
      !focusedProject?.gdpr_questions?.length
    ) {
      let payload = {
        ...focusedProject,
        gdpr_questions: [
          { question: "" },
          { question: "" },
          { question: "" },
          { question: "" },
        ],
      };
      dispatch(setSelectedProject(payload));
      dispatch(setFocusedProject(payload));
    } else {
      dispatch(setSelectedProject(focusedProject));
    }
  }, []);
  const [isApiKeyUpdateModal, setIsApiKeyModal] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [copiedUrl, setCopiedUrl] = useState(false);
  const [addImageModal, setAddImgesModal] = useState(false);
  const [uploadedBackgroundImg, setUploadedBackgroundImg] = useState();
  const [loading, setLoading] = useState(false);
  const [uploadedImage, setuploadedImage] = useState();
  const dispatch = useDispatch();

  const { projectId } = useParams();
  const { origin } = window.location;
  const handleOnChnage = (e) => {
    const { name, value, files } = e.target;
    if (
      name === "bot_image" ||
      name === "background_image" ||
      name === "agent_logo"
    ) {
      if (name === "background_image") {
        setuploadedImage(files[0]);
        setAddImgesModal(true);
        return;
      }
      dispatch(setSelectedProject({ ...selectedProject, [name]: files[0] }));

      return;
    }

    dispatch(setSelectedProject({ ...selectedProject, [name]: value }));
  };
  const handleInputChange = (event, index) => {
    const { value } = event.target;

    dispatch(setUpdateSelectedProject({ index, value }));
  };

  const handleOnSubmit = () => {
    setLoading(true);
    let formData = new FormData();
    Object.keys(selectedProject).forEach((key) => {
      if (key === "id") {
        formData.append("project_id", selectedProject[key]);
      }
      if (key == "gdpr_questions") {
        // selectedProject.gdpr_question.map((item)=>{
        formData.append(
          "gdpr_questions",
          JSON.stringify(selectedProject?.gdpr_questions)
        );
        return;
        // })
      }
      formData.append(key, selectedProject[key]);
    });
    formData.append("answer_limit", "10");
    dispatch(updateBrand(formData))
      .unwrap()
      .then((res) => {
        if (!res?.data?.success) {
          setLoading(false);
          return;
        }
        if (res?.data?.success) {
          setLoading(false);
          toast.success(res?.data?.message);
        }
      });
  };
  const handleVisibility = () => {
    const body = {
      id: projectId,
      is_published: !selectedProject?.is_published,
    };
    dispatch(changeProjectVisibility(body))
      .unwrap()
      .then((res) => {
        dispatch(
          setSelectedProject({
            ...selectedProject,
            is_published: res?.data?.data?.is_published,
          })
        );
        dispatch(
          setFocusedProject({
            ...focusedProject,
            is_published: res?.data?.data?.is_published,
          })
        );
      });
  };
  const handleCopyText = (data) => {
    navigator.clipboard.writeText(data);
    setCopiedUrl(true);
    setTimeout(() => {
      setCopiedUrl(false);
    }, 500);
  };
  const handleOnClickReset = () => {
    dispatch(setSelectedProject(focusedProject));
  };

  const handleRefreshApiKey = () => {
    let body = {
      id: selectedProject?.id,
    };
    dispatch(refreshApiKey(body))
      .unwrap()
      .then((res) => {
        if (!res?.data?.success) {
          toast.error(res?.data?.message);
          return;
        }
        handleCloseModal();
        toast.success(res?.data?.message);
      });
  };
  const handleOnRemoveChatBotIcon = (name) => {
    dispatch(
      setSelectedProject({ ...selectedProject, [name]: focusedProject[name] })
      // setSelectedProject({ ...selectedProject, [name]: "" })
    );

    setUploadedBackgroundImg("");
  };
  const [imageData, setImageData] = useState("");

  useEffect(() => {
    if (imageData) {
      // const modifiedData = uploadedFiles?.contetent?.map((item) => {
      //   const itemData = item?.data?.name ? item?.data?.name : item?.name;
      //   if ((itemData === imageData?.photo?.data?.name) === true) {
      //     return {
      //       data: item?.data ? item?.data : item,
      //       src: imageData?.photo?.src,
      //     };
      //   }
      //   return item;
      // });
      // setUploadedFiles({ ...uploadedFiles, contetent: modifiedData });
      var file = base64ToBlob(imageData?.photo?.src);
      setUploadedBackgroundImg(file);
      dispatch(
        setSelectedProject({ ...selectedProject, background_image: file })
      );
      setImageData("");
    }
  }, [imageData]);

  const handleCloseModal = () => {
    setIsApiKeyModal(false);
  };

  let compareObject =
    JSON.stringify(selectedProject)?.replace(/ /g, "") ===
    JSON.stringify(focusedProject)?.replace(/ /g, "");
  return (
    <div className="chatbot-details-all-contnet-alignment">
      <h5>Chatbot</h5>
      <div className="box">
        <Tab setIsCompany={setIsCompany} isCompany={isCompany} />
        <div className="two-col-grid">
          <Input
            label="Bot Name"
            placeholder="RTA"
            name={"bot_name"}
            value={selectedProject?.bot_name}
            onChange={handleOnChnage}
            limit={25}
          />
          <Input
            label="Tagline / Custom Label"
            placeholder="Our bot is answer instlantly"
            name={"project_moto"}
            value={selectedProject?.project_moto}
            onChange={handleOnChnage}
            limit={40}
          />
          {/* <Input label="Bot text colour"/> */}
          <div className="input">
            <label>Bot text colour</label>
            <select
              placeholder="Black"
              name={"text_color"}
              value={selectedProject?.text_color}
              onChange={handleOnChnage}
            >
              <option value={"white"}>White</option>
              <option value={"black"}>Black</option>
            </select>
          </div>
          <Input
            type={"color"}
            label="Bot Color"
            placeholder="#7fb8b2"
            name={"base_color"}
            value={
              selectedProject?.base_color === null
                ? "#008000"
                : selectedProject?.base_color
            }
            onChange={handleOnChnage}
          />

          <div className="col-full">
            <Input
              label="Welcome message"
              placeholder="Hello there!s Welcome to our community!"
              name={"welcome_title"}
              value={selectedProject?.welcome_title}
              onChange={handleOnChnage}
            />
          </div>
        </div>
        <div className="new-drop-grid">
          <Chooseimage
            name={"bot_image"}
            onChange={handleOnChnage}
            label={"Chatbot Icon"}
            value={selectedProject?.bot_image}
            onRemove={() => handleOnRemoveChatBotIcon("bot_image")}
            imageValue={selectedProject?.bot_image}
          />
          <Chooseimage
            name={"background_image"}
            onChange={handleOnChnage}
            label={"Background Image"}
            value={selectedProject?.background_image}
            imageValue={uploadedBackgroundImg}
            onRemove={() => handleOnRemoveChatBotIcon("background_image")}
          />

          <ImageInput
            imageData={imageData?.photo?.src}
            type="admin"
            name="photo"
            label="Add Photo"
            showPreview
            onChange={(files) => setImageData(files, "admin")}
            fileData={uploadedImage}
            // OriginalImage={OriginalImage}
            setAddImgesModal={setAddImgesModal}
            addImageModal={addImageModal}
          />
        </div>
        {user?.is_gdpr && (
          <div className="col-full">
            <div className="input">
              <label style={{ padding: "0px" }}>Add Question</label>
              {selectedProject?.gdpr_questions?.length > 0 &&
                selectedProject?.gdpr_questions?.map((item, index) => {
                  return (
                    <Input
                      // label="Welcome message"
                      placeholder="Hello there!s Welcome to our community!"
                      name={`question${index}`}
                      value={item.question}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  );
                })}
            </div>
          </div>
        )}
        {!isCompany && (
          <div className="button-alignment">
            <Button
              text="Save changes"
              onClick={handleOnSubmit}
              loader={loading}
              disabled={compareObject}
            />
            <Button
              text="Reset to default"
              onClick={handleOnClickReset}
              className="outline"
            />
          </div>
        )}
      </div>
      {isCompany && (
        <div className="company-box">
          <div className="company-grid">
            <Input
              label="Agent  Name"
              name={"agent_name"}
              value={
                selectedProject?.agent_name === "null"
                  ? ""
                  : selectedProject?.agent_name
              }
              placeholder="Your agent name"
              onChange={handleOnChnage}
            />
            <div>
              <Chooseimage
                name={"agent_logo"}
                onChange={handleOnChnage}
                label={"Agent logo"}
                value={selectedProject?.agent_logo}
                onRemove={() => handleOnRemoveChatBotIcon("agent_logo")}
              />
            </div>
          </div>
          {isCompany && (
            <div className="button-alignment">
              <Button
                text="Save changes"
                onClick={handleOnSubmit}
                loader={loading}
                disabled={compareObject}
              />
              <Button
                text="Reset to default"
                onClick={handleOnClickReset}
                className="outline"
              />
            </div>
          )}
        </div>
      )}

      <div
        className="sub-box"
        title={
          compareObject
            ? "Please save your changes or click reset to perform this action!"
            : ""
        }
      >
        <div
          className="two-col"
          style={{
            pointerEvents: !compareObject ? "none" : "all",
            opacity: compareObject ? 1 : 0.6,
          }}
        >
          <Input
            label="Visibile to public"
            icon={CopyIcon}
            switchtoogle={true}
            isSwitchTrue={selectedProject?.is_published}
            handleVisibility={handleVisibility}
            // iconClick={handleVisibility}
            iconClick={() => {
              selectedProject?.is_published &&
                handleCopyText(
                  `${origin}/${isKnowledgeChat ? "chat" : "generalchat"}/${
                    selectedProject?.api_key
                  }`
                );
            }}
            value={`${origin}/${isKnowledgeChat ? "chat" : "generalchat"}/${
              selectedProject?.api_key
            }`}
            placeholder="https://dribbble.com/following"
            disabled={!selectedProject?.is_published}
            isPointerNone={!selectedProject?.is_published || !compareObject}
            copiedUrl={copiedUrl}
          />
          <div>
            <p>Project specific API key</p>
            {/* <span>{selectedProject?.api_key}</span> */}
            <div className="refersh" onClick={() => setIsApiKeyModal(true)}>
              <img src={ReloadIcon} alt="ReloadIcon" />
            </div>
          </div>
        </div>
      </div>

      <Conformation
        title={"Refresh Api Key"}
        btnText={"Confirm"}
        onClick={handleRefreshApiKey}
        onClose={() => handleCloseModal()}
        message={
          "Are you sure, you want to generate new key for this project? If you click confirm your old link will expired"
        }
        icon={UpdateIcon}
        isModal={isApiKeyUpdateModal}
      />
    </div>
  );
}
