import React from "react";
import "./settings.scss";
import Myproject from "../dashboard/myproject";
import Settingstab from "./settingstab";
import Information from "./information";
import Plandetails from "./plandetails";
import Subscription from "./subscription";

import { useParams } from "react-router-dom";
export default function Settings() {
  const {route}=useParams()

  return (
    <div>
      <Myproject />

      <Settingstab />
      {route === "account" && (
        <>
          <Information />
          <Plandetails />
        </>
      )}
      {route === "subscription" &&<Subscription />}
      {/* <Conformation/> */}
    </div>
  );
}
