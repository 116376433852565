import React from "react";
import "./entermessagebox.scss";
import SendIcon from "../../../assets/icons/send.svg";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { createGeneralChat } from "../../../store/slices/projectSlice";
import { useLocation, useParams } from "react-router-dom";
import { getSubscription } from "../../../store/slices/planSlice";
import { getUser } from "../../../helpers/utils/auth.util";
export default function Entermessagebox(props) {
  const {
    setChatWithBot,
    tempUserData,
    loading,
    setLoading,
    scrollToBottom,
    isKnowledgeChat,
  } = props;
  const { focusedProject, selectedProject } = useSelector(
    (state) => state.project
  );
  const user = getUser();
  const [createChat, setCreateChat] = useState({ question: "" });
  const [errors, setErrors] = useState({});
  const { id } = useParams();
  const { pathname } = useLocation();
  const { route } = useParams();
  const dispatch = useDispatch();

  const validForm = () => {
    let Fromvlid = true;
    let errors = {};
    if (!createChat.question?.trim()) {
      Fromvlid = false;
      errors["question"] = "Question is required*";
    }
    setErrors(errors);
    return Fromvlid;
  };

  const handleCreateChat = (e, data, type) => {
    e.preventDefault();
    if (!validForm() && type != "direct") return;
    setLoading(true);
    setChatWithBot((prev) => [
      ...prev,
      type == "direct" ? data : createChat?.question,
    ]);

    const body = {
      api_key:
        pathname.includes("generalchat") || pathname.includes("/chat/")
          ? id
          : focusedProject?.api_key,
      session_id: tempUserData,
      question: type != "direct" ? createChat?.question : data,
      route:
        isKnowledgeChat && pathname.includes("/chatbot-settings/")
          ? "chat/chat"
          : pathname.includes("generalchat")
          ? "chat/general-pub-chat"
          : pathname.includes("/chat/")
          ? "chat/pub-chat"
          : "chat/general-chat",
    };
    setCreateChat({ question: "" });
    scrollToBottom();
    dispatch(createGeneralChat(body))
      .unwrap()
      .then((res) => {
        setCreateChat({ question: "" });
        setChatWithBot((prev) => [...prev, res?.data?.data]);
        setLoading(false);
        scrollToBottom();
        dispatch(getSubscription());
      });
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setCreateChat({ ...createChat, [name]: value });
  };

  return (
    <div>
      <form
        onSubmit={(e) => {
          route !== "chat-history" && !loading && handleCreateChat(e);
        }}
      >
        <div className="enter-message-box">
          {!pathname.includes("project/chat-history/") &&
            selectedProject?.gdpr_questions?.length > 0 && (
              <div className="message-flex-other-wrap">
                {selectedProject?.gdpr_questions?.length > 0 &&
                  selectedProject?.gdpr_questions?.map((item) => {
                    return (
                      item?.question && (
                        <div
                          className="message-style-new"
                          onClick={(e) =>
                            handleCreateChat(e, item?.question, "direct")
                          }
                        >
                          {item?.question}
                        </div>
                      )
                    );
                  })}
              </div>
            )}
          <div className="relative">
            <input
              type="text"
              placeholder="Enter your message..."
              name="question"
              onChange={(e) => handleOnChange(e)}
              value={createChat?.question}
              disabled={route === "chat-history"}
              autoComplete="off"
            />
            <div
              className="icon-alignment"
              onClick={(e) => {
                route !== "chat-history" && !loading && handleCreateChat(e);
              }}
            >
              <img src={SendIcon} alt="SendIcon" />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
