import React from "react";
import "./plandetails.scss";
import Button from "../../../components/button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
export default function Plandetails() {
const {selectedPlan}=useSelector((state)=>state.subscription)

const navigate=useNavigate()
  return (
    <div className="plan-details-contnet-alignment">
      <div className="container">
        <div className="card">
          <div className="card-header">
            <span>Current Plan</span>
          </div>
          <div className="card-body">
            <div>
                <p>Plan Name : { selectedPlan?.name}</p>
                <p>Status : <span>{selectedPlan?.is_active?"Active":"Inactive"}</span></p>
            </div>
            <div>
                <p>Total Message:  {selectedPlan?.total_meassges}</p>
                <p>Remaining Message:  {selectedPlan?.remaining_messages}</p>
            </div>
            <div>
                <Button text="Update Subscription" onClick={()=>navigate("/settings/subscription")}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
