import React, { useEffect, useState } from "react";
import "./chooseimage.scss";
import CloseIconMd from "../../../../assets/icons/close-d.svg";
import GalleryIcon from "../../../../assets/icons/gallery.svg";
import { useLocation } from "react-router-dom";

export default function Chooseimage({
  onChange,
  name,
  value,
  label,
  onRemove,
  type,
}) {
  const [bgImage, setBgImage] = useState();
  const { pathname } = useLocation();
  useEffect(() => {
    const BotBackgroundImage =
      value instanceof Blob || value instanceof File
        ? URL.createObjectURL(value)
        : typeof value == "string"
        ? value
        : "";
    setBgImage(BotBackgroundImage);
  }, [value]);
  const [imgValue, setImgValue] = useState("");

  const handleOnChange = (e) => {
    setImgValue("");
    e.preventDefault();
    e.stopPropagation();
    onChange && onChange(e);
  };
  return (
    <div className="choose-image-contnet-alignment">
      <label>{label}</label>
      <div className="drop-grid">
        <div style={{ display: "flex", width: "100%", gap: "10px" }}>
          <div className="drop-grid-items" style={{ width: "100%" }}>
            {!value?.name?.includes("Please upload") &&
              !pathname.includes("chatbot-settings") && (
                <div
                  style={{ position: "absolute", right: "-5px", top: "-10px" }}
                  className="close-icons"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onRemove && onRemove();
                  }}
                >
                  <img src={CloseIconMd} alt="CloseIconMd" />
                </div>
              )}
            <img src={GalleryIcon} alt="GalleryIcon" />
            <span>{value?.name ? value?.name : "Choose image"}</span>
            <input
              type={type || "file"}
              name={name}
              onChange={(e) => {
                handleOnChange(e);
              }}
              value={imgValue}
            />
          </div>
          {bgImage && pathname.includes("chatbot-settings") ? (
            <div style={{ position: "relative" }}>
              <div
                style={{ position: "absolute", right: "-5px", top: "-10px" }}
                className="close-icons"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onRemove && onRemove();
                }}
              >
                <img src={CloseIconMd} alt="CloseIconMd" />
              </div>
              <img
                style={{ height: "70px", width: "70px" }}
                src={bgImage}
                alt="bgImage"
              />
            </div>
          ) : (
            ""
          )}
        </div>
        {/* <div className='drop-grid-items'>
            <img src={GalleryIcon} alt="GalleryIcon"/>
            <span>Choose image</span>
            <input type='file'/>
        </div> */}
      </div>
    </div>
  );
}
