import React from "react";
import Header from "../header";
export default function DefaultLayout({ children }) {
  return (
    <>
      <Header />

      {children}
    </>
  );
}
