import {  createSlice} from "@reduxjs/toolkit";
const initialState = {

};



export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {

  },
  extraReducers(builder) {

      
  },
});

export default dashboardSlice;
